import React from "react";
import facebook from './facebook.png';
import twitter from './twitter.png';
import insta from './instagram.png';
import linked from './linked.png';
import link from "./link.png";
import {Grid} from "@mui/material";
import {Link} from "gatsby";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject} from "../../utils/constant.urls";
import {FaLinkedin, FaTiktok} from "react-icons/fa";

const QuikTaskfacebookLink = environment.companyInfo.faceBookLink;
const QuikTaskinstagramLink = environment.companyInfo.instagramLink;
const QuikTasktiktokLink = environment.companyInfo.TiktokLink;

const SocialFlow = () => {
  const copy = async () => {
    await navigator.clipboard.writeText(window.location.href);
  };
  return (
    <Grid container>
      <Link
        to={isQuickTaskProject ? QuikTaskfacebookLink : "https://www.facebook.com/Sydetasker2020/"}
      >
        <Grid item xs={2} md={2} lg={2} xl={2}>
          <img
            alt="facebook"
            loading="lazy"
            style={{width: "24px", height: "24px"}}
            src={facebook}
          ></img>
        </Grid>
      </Link>
      <Link
        to={
          isQuickTaskProject
            ? QuikTaskinstagramLink
            : "https://www.instagram.com/sydetaskerapp/?hl=en"
        }
      >
        <Grid item xs={2} md={2} lg={2} xl={2}>
          <img
            loading="lazy"
            alt="instagram"
            style={{width: "24px", height: "24px"}}
            src={insta}
          ></img>
        </Grid>
      </Link>
      {!isQuickTaskProject && (
        <Link to={"https://twitter.com/sydetasker"}>
          <Grid item xs={2} md={2} lg={2} xl={2}>
            <img
              alt="twitter"
              loading="lazy"
              style={{width: "24px", height: "24px"}}
              src={twitter}
            ></img>
          </Grid>
        </Link>
      )}
      {isQuickTaskProject ? (
        <Link to={QuikTasktiktokLink}>
          <Grid item xs={2} md={2} lg={2} xl={2}>
            <FaTiktok style={{color: "#333333", marginTop: "2px", marginLeft: "3px"}} size={20} />{" "}
          </Grid>
        </Link>
      ) : (
        <Link to={"https://www.linkedin.com/company/sydetasker"}>
          <Grid item xs={2} md={2} lg={2} xl={2}>
            <FaLinkedin style={{color: "white"}} size={20} />{" "}
          </Grid>
        </Link>
      )}
      <Grid item xs={2} md={2} lg={2} xl={2}>
        <img
          alt="link"
          loading="lazy"
          onClick={copy}
          style={{width: "24px", height: "24px"}}
          src={link}
        ></img>
      </Grid>
    </Grid>
  );
};

export default  SocialFlow;