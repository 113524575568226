import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import SEO from "../components/seo/seo";
import Text from "../components/data-display/text";
import { FiChevronDown } from "react-icons/fi";
import { useTheme } from "@mui/system";
import RichTextEditor from "../components/support-components/rich-text-editor";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import InlineLink from "../components/data-display/inline-link";
import { Collapse } from "@mui/material";
import { FiChevronRight } from "react-icons/fi";
import Article from "../components/support-components/article";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import SocialFlow from "../components/article-component/social-icons";
import { longDateFormatWith_DD } from "../utils/constant.urls";

const ArticlePage = ({
  pageContext: { article, sections, segmentSlug },
}: {
  pageContext: {
    article: Article;
    sections: Record<string, any>;
    segmentSlug: string;
  };
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedSection, setSelectedSection] = useState<string[]>([
    article.sectionSlug,
  ]);

  return (
    <div
      style={{
        background: "white",
        minHeight: "80vh",
        borderBottom: "1px solid rgb(220,220,220)",
      }}
    >
      <SEO description={""} title={article.title} />
      <div style={{ height: "100px" }} />
      <div
        style={{
          zIndex: 1251,
          position: "fixed",
          width: "100%",
          top: "64px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          background: "white",
          borderBottom: "2px solid rgba(175,175,175,0.15)",
        }}
      >
        <Container maxWidth={"lg"}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<FiChevronRight size={12} color={"grey"} />}
          >
            <Text variant={"caption"} medium style={{ opacity: "0.7" }}>
              <InlineLink
                to="/support"
                variant={"secondary"}
                style={{ color: "black" }}
              >
                Support
              </InlineLink>
            </Text>
            <Text variant={"caption"} medium style={{ opacity: "0.7" }}>
              <InlineLink
                to="/support"
                variant={"secondary"}
                style={{ color: "black" }}
              >
                {segmentSlug === "taskers" ? "Taskers" : "Posters"}
              </InlineLink>
            </Text>
            <Text variant={"caption"} medium style={{ opacity: "0.7" }}>
              <InlineLink
                to={`/support/${segmentSlug}/${article.categorySlug}`}
                variant={"secondary"}
                style={{ color: "black" }}
              >
                {article.category}
              </InlineLink>
            </Text>
            <Text variant={"caption"} medium style={{ opacity: "0.7" }}>
              <InlineLink
                to={`/support/${segmentSlug}/${article.categorySlug}`}
                variant={"secondary"}
                style={{ color: "black" }}
              >
                {article.section}
              </InlineLink>
            </Text>
          </Breadcrumbs>
        </Container>
      </div>
      <Container maxWidth={"lg"}>
        <div style={{ height: "64px" }} />
        <Grid container>
          {xsDown ? null : (
            <Grid item lg={4} xl={4}>
              <div style={{ padding: "32px" }}>
                {sections.map((section) => {
                  return (
                    <div
                      style={{
                        marginBottom: selectedSection.includes(
                          section.edges[0].node.sectionSlug
                        )
                          ? "18px"
                          : "4px",
                      }}
                    >
                      <div
                        style={{
                          padding: "4px 0px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (
                            selectedSection.includes(
                              section.edges[0].node.sectionSlug
                            )
                          ) {
                            let d = [...selectedSection];
                            d.splice(
                              d.indexOf(section.edges[0].node.sectionSlug),
                              1
                            );
                            setSelectedSection(d);
                          } else {
                            setSelectedSection([
                              ...selectedSection,
                              section.edges[0].node.sectionSlug,
                            ]);
                          }
                        }}
                      >
                        <Text
                          variant={"body2"}
                          regular
                          style={{ color: "rgb(40,40,40)" }}
                        >
                          {section.fieldValue}
                        </Text>
                        <FiChevronDown style={{ opacity: "0.8" }} size={20} />
                      </div>
                      <div style={{ height: "14px" }} />
                      <Collapse
                        in={selectedSection.includes(
                          section.edges[0].node.sectionSlug
                        )}
                      >
                        {section.edges.map((questions) => {
                          return (
                            <InlineLink
                              to={`/support/${segmentSlug}/${questions.node.categorySlug}/${questions.node.sectionSlug}/${questions.node.slug}`}
                            >
                              <div
                                style={{
                                  padding: "12px 24px",
                                  borderRadius: "6px",
                                  background:
                                    questions.node.slug === article.slug
                                      ? "rgb(78, 62, 253,0.13)"
                                      : "transparent",
                                }}
                              >
                                <Text
                                  variant={"body2"}
                                  regular
                                  style={{ color: "rgb(40,40,40)" }}
                                >
                                  {questions.node.title}
                                </Text>
                              </div>
                            </InlineLink>
                          );
                        })}
                      </Collapse>
                    </div>
                  );
                })}
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
            <Text black variant={"h4"} style={{ color: "rgb(40,40,40)" }}>
              {article.title}
            </Text>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                boxSizing: "inherit",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Text variant={"body2"} regular style={{ opacity: "0.7" }}>
                Updated:{" "}
                {moment(article.updateAt).format(longDateFormatWith_DD)}
              </Text>
            </div>
            <div style={{ height: xsDown ? "24px" : "40px" }} />
            <hr
              style={{
                backgroundColor: "red",
                color: "red",
                background: "red",
              }}
            />
            <div style={{ height: xsDown ? "24px" : "40px" }} />
          {article?.body?.length > 0 &&
            <RichTextEditor
              value={
                typeof article?.body === "string"
                  ? JSON.parse(article?.body)
                  : article?.body
              }
              placeholder={""}
              readOnly
            />}
            <div>
              <SocialFlow />
            </div>
          </Grid>
        </Grid>
      </Container>
      <div style={{ height: "64px" }} />
    </div>
  );
};

export default ArticlePage;
